import React from 'react'
import ReactDOM from 'react-dom'
import { Copyright, Link, Portal } from 'react-portal'
import { MaintenanceNotification } from 'rit-maintenance-notification'
import steps from './steps.json'
import imgSrcLogin from './assets/images/login.png'
import imgSrcLogo from './assets/images/logo.svg'
import imgSrcTour from './assets/images/tour.png'
import 'rit-maintenance-notification/dist/index.css'
import './assets/scss/main.scss'

const Footer = () => (
  <>
    <p>
      {'Contact Us at '}
      <Link url='mailto:HCSOhiosupport@osu.edu'>HCSOhiosupport@osu.edu</Link>
    </p>
    <p>
      NIH HEAL Initiative and Helping to End Addiction Long-term are service
      marks of the U.S. Department of Health and Human Services.
    </p>
    <p>
      <Copyright startYear={2020} />
      {' • '}
      <Link url='https://help.hcs.containers.it.osu.edu/terms-of-use/'>
        Terms of Use
      </Link>
      {' • '}
      <Link url='https://it.osu.edu/privacy'>Privacy Policy</Link>
      {' • '}
      <Link url='https://ccts.osu.edu/content/citing-language#:~:text=Please%20cite%20The%20Ohio%20State,publications%20relating%20to%20this%20project'>
        CCTS citation
      </Link>
    </p>
  </>
)

ReactDOM.render(
  <>
    <MaintenanceNotification appName='Heal' />
    <Portal
      categoryRenderer={() => null}
      footerRenderer={Footer}
      {...{
        imgSrcLogin,
        imgAltLogin: 'HEAL - NIH - Helping to End Addiction Long-term',
        imgSrcLogo,
        imgAltLogo: 'NIH HEAL INITIATIVE - HEALing Communities Study Ohio',
        imgSrcTour,
        steps
      }}
    />
  </>,
  document.getElementById('root')
)
